import React, { useState, useEffect } from 'react'
import axios from 'axios'
import styles from './weather.module.css'
import DailyForecast from './DailyForecast/DailyForecast'
import CurrentConditions from './CurrentConditions/CurrentConditions'
import Spinner from '../sharedComponents/Spinner/Spinner'
import PropTypes from 'prop-types'

const Weather = ({ weatherStation, latitude, longitude }) => {
  const coordinates = [latitude, longitude]
  const [forecast, setForecast] = useState(null)
  useEffect(() => {
    const fetchForecast = async () => {
      const result = await axios.post(
        `${process.env.GATSBY_WEATHER_API_BASE_URL}/forecast`,
        coordinates
      )
      setForecast(result.data)
    }
    fetchForecast()
  }, [])

  const dailyForecasts = forecast
    ? forecast.daily.daily.slice(1).map((dailyForecast, index) => {
        return <DailyForecast dailyForecast={dailyForecast} key={index} />
      })
    : null

  return (
    <>
      {!forecast ? (
        <div className={styles.spinnerContainer}>
          <Spinner />
          <div className={styles.weatherMessage}>Weather is loading...</div>
        </div>
      ) : (
        <>
          <div className={styles.weatherContainer}>
            <div className={styles.currentWeatherContainer}>
              <CurrentConditions
                currentConditions={forecast.currently}
                dailyForecast={forecast.daily.daily[0]}
              />
            </div>
            <div className={styles.forecastContainer}>{dailyForecasts}</div>
          </div>
          <div className={styles.updates}>
            <div>{`Last updated at ${forecast.currently.currentTime} (${forecast.timezone})`}</div>
            <div>{weatherStation}</div>
          </div>
        </>
      )}
    </>
  )
}

Weather.propTypes = {
  weatherStation: PropTypes.string,
  latitude: PropTypes.string,
  longitude: PropTypes.string,
}

export default Weather
