import React from 'react'
import styles from './spinner.module.css'

const Spinner = () => {
  return (
    <div className={styles.spinnerCircle}>
      <div className={styles.ldsRing}>
        <div> </div>
        <div> </div>
        <div> </div>
        <div> </div>
      </div>
    </div>
  )
}

export default Spinner
