import React from 'react'
import styles from './dailyForecast.module.css'
import PropTypes from 'prop-types'
import WeatherIcon from '../WeatherIcon'

const DailyForecast = ({ dailyForecast }) => {
  const {
    dayAbbreviated,
    icon,
    temperatureHigh,
    temperatureLow,
  } = dailyForecast
  return (
    <div className={styles.dailyContainer}>
      <div className={styles.temperatureHighLow}>{dayAbbreviated}</div>
      <div className={styles.temperatureHighLow}>
        <WeatherIcon icon={icon} size="34" />
      </div>
      <div className={styles.temperatureHigh}>{temperatureHigh}</div>
      <div className={styles.temperatureLow}>{temperatureLow}</div>
    </div>
  )
}

DailyForecast.propTypes = {
  dailyForecast: PropTypes.object,
}

export default DailyForecast
