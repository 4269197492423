import React, { useState } from 'react'
import { GiPathDistance } from 'react-icons/gi'
import { IconContext } from 'react-icons'
import { Link } from 'gatsby'
import Spinner from '../../../components/sharedComponents/Spinner/Spinner'
import { FaClipboardList, FaMountain, FaYoutube } from 'react-icons/fa'
import PropTypes from 'prop-types'
import styles from './trail.module.css'

const Trail = ({
  summary,
  difficulty,
  length,
  ascent,
  descent,
  high,
  low,
  videoId,
  hiking_map_coords,
  trail_features,
  trail_type,
}) => {
  const [isIframeLoaded, setIsiFrameLoaded] = useState(false)
  const features = trail_features.map((feature, index) => (
    <div key={index} className={styles.trailAttribute}>
      {feature}
    </div>
  ))

  return (
    <div className={styles.trail}>
      <div className={styles.summary}>{summary}</div>
      <div className={styles.trailAttributes}>
        <div className={styles.trailSection}>
          <div className={styles.trailHeader}>
            <IconContext.Provider value={{ color: '#000000', size: '40' }}>
              <GiPathDistance />
            </IconContext.Provider>
            <div className={styles.trailHeaderLabel}>Trail Details</div>
          </div>
          <div className={styles.attributesGroup}>
            <div className={styles.trailAttribute}>Type: {trail_type}</div>
            <div
              className={styles.trailAttribute}
            >{`Distance: ${length} Miles`}</div>
            <div
              className={styles.trailAttribute}
            >{`Difficulty: ${difficulty}`}</div>
            <div className={styles.trailAttribute}>
              <div className={styles.videoLink}>
                <IconContext.Provider value={{ color: '#FF0000', size: '22' }}>
                  <FaYoutube />
                </IconContext.Provider>
                <div className={styles.videoLinkText}>
                  <Link
                    className={styles.link}
                    to="/videos"
                    state={{ videoId: videoId }}
                  >
                    Watch Video
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.trailSection}>
          <div className={styles.trailHeader}>
            <IconContext.Provider value={{ color: '#000000', size: '40' }}>
              <FaMountain />
            </IconContext.Provider>
            <div className={styles.trailHeaderLabel}>Elevation</div>
          </div>
          <div className={styles.attributesGroup}>
            <div
              className={styles.trailAttribute}
            >{`Ascent: ${ascent} feet`}</div>
            <div
              className={styles.trailAttribute}
            >{`Descent: ${descent} feet`}</div>
            <div className={styles.trailAttribute}>{`High: ${high} feet`}</div>
            <div className={styles.trailAttribute}>{`Low: ${low} feet`}</div>
          </div>
        </div>
        <div className={styles.trailSection}>
          <div className={styles.trailHeader}>
            <IconContext.Provider value={{ color: '#000000', size: '40' }}>
              <FaClipboardList />
            </IconContext.Provider>
            <div className={styles.trailHeaderLabel}>Features</div>
          </div>
          <div className={styles.attributesGroup}>{features}</div>
        </div>
      </div>
      {hiking_map_coords && hiking_map_coords.length ? (
        <div className={styles.map}>
          {!isIframeLoaded ? (
            <div className={styles.loaderOverlay}>
              <div>
                <div>Loading Map...</div>
                <Spinner />
              </div>
            </div>
          ) : null}
          <iframe
            style={{ width: '100%', maxWidth: '1200px', height: '400px' }}
            frameBorder="0"
            scrolling="no"
            onLoad={() => setIsiFrameLoaded(true)}
            src={`https://www.hikingproject.com/widget/map?favs=0&location=fixed&x=${hiking_map_coords[0]}&y=${hiking_map_coords[1]}&z=${hiking_map_coords[2]}&h=400`}
          />
        </div>
      ) : null}
    </div>
  )
}
Trail.propTypes = {
  summary: PropTypes.string,
  difficulty: PropTypes.string,
  ascent: PropTypes.number,
  descent: PropTypes.number,
  high: PropTypes.number,
  low: PropTypes.number,
  length: PropTypes.number,
  videoId: PropTypes.string,
  hiking_map_coords: PropTypes.array,
  trail_features: PropTypes.array,
  trail_type: PropTypes.string,
}
export default Trail
