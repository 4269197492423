import React from 'react'
import AdventureInformation from '../../components/InformationPages/AdventureInformation/AdventureInformation'
import AllVideosForSinglePost from '../../components/SuggestedVideos/AllVideosForSinglePost/AllVideosForSinglePost'
import { BlogData } from '../../contexts/blogData/blogData'
import { CampingData } from '../../contexts/campingData/campingData'
import CampingInformation from '../../components/InformationPages/CampingInformation/CampingInformation'
import CategoryLinkSelector from '../../components/Selectors/CategoryLinkSelector/CategoryLinkSelector'
import { getFluidImage } from '../../helpers/dynamicGatsbyImage'
import { graphql } from 'gatsby'
import { LodgingData } from '../../contexts/lodgingData/lodgingData'
import LodgingInformation from '../../components/InformationPages/LodgingInformation/LodgingInformation'
import SEO from '../../components/seo'
import SideBar from '../../components/sideBar/SideBar'
import TwoColumnLayout from '../../components/twoColumnLayout/TwoColumnLayout'
import { VideoData } from '../../contexts/videoData/videoData'
import PropTypes from 'prop-types'
import AdSense from 'react-adsense'
import MediaQuery from '../../components/sharedComponents/MediaQuery/MediaQuery'

const queries = {
  mobile: '(max-width: 999px)',
  desktop: '(min-width: 1000px)',
}

const InformationPage = ({ data }) => {
  const matchPoints = MediaQuery(queries)
  const post = data.markdownRemark.frontmatter
  const fluidImg = getFluidImage(post, data.allFile.nodes)
  const title = `${data.markdownRemark.frontmatter.displayName} | Hours, Prices, Directions and Information`
  const description = `Useful and up-to-date information about ${data.markdownRemark.frontmatter.displayName} to help you plan a visit.`
  const {
    site: {
      siteMetadata: { url },
    },
  } = data
  return (
    <>
      <SEO
        title={title}
        description={description}
        thumbnail={data.markdownRemark.frontmatter.thumbnail}
        url={`${url}/information/${data.markdownRemark.frontmatter.slug}/`}
        type="article"
      />
      <TwoColumnLayout>
        <BlogData>
          <CampingData>
            <LodgingData>
              <VideoData>
                {post.blogType === 'lodging' ? (
                  <LodgingInformation
                    allImages={data.allFile.nodes}
                    distances={
                      data.allDistanceMatrixData.edges[0].node.distanceMatrix
                    }
                    fluidImg={fluidImg}
                    videoId={post.videoId}
                  />
                ) : null}
                {post.blogType === 'camping' ? (
                  <CampingInformation
                    allImages={data.allFile.nodes}
                    distances={
                      data.allDistanceMatrixData.edges[0].node.distanceMatrix
                    }
                    fluidImg={fluidImg}
                    videoId={post.videoId}
                  />
                ) : null}
                {post.blogType === 'adventure' ? (
                  <AdventureInformation
                    allImages={data.allFile.nodes}
                    fluidImg={fluidImg}
                    videoId={post.videoId}
                  />
                ) : null}
                <SideBar>
                  <AllVideosForSinglePost
                    reviewId={post.videoId}
                    type={post.blogType}
                  />
                  <CategoryLinkSelector />
                  {matchPoints && matchPoints.desktop ? (
                    <AdSense.Google
                      client="ca-pub-1156710604979342"
                      slot="8925894875"
                      style={{
                        display: 'inline-block',
                        width: '300px',
                        height: '250px',
                        position: 'sticky',
                        top: '184px',
                      }}
                    />
                  ) : null}
                </SideBar>
              </VideoData>
            </LodgingData>
          </CampingData>
        </BlogData>
      </TwoColumnLayout>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        displayName
        videoId
        thumbnail
        blogType
      }
    }
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    allFile(filter: { extension: { ne: "md" } }) {
      nodes {
        childImageSharp {
          id
          fluid(maxWidth: 1210) {
            ...GatsbyImageSharpFluid
          }
        }
        relativePath
        extension
      }
    }
    allDistanceMatrixData {
      edges {
        node {
          distanceMatrix {
            rows {
              elements {
                distance {
                  text
                  value
                }
                status
              }
            }
            destination_ids
            origin_ids
          }
        }
      }
    }
  }
`
InformationPage.propTypes = {
  data: PropTypes.object,
}
export default InformationPage
