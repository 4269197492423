import React, { useContext } from 'react'
import ArticleButton from '../../sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../sharedComponents/ArticleTitle/ArticleTitle'
import BlogContext from '../../../contexts/blogData/blogData'
import { computePriceRange } from '../helpers'
import InfoSection from '../../sharedComponents/InfoSection/InfoSection'
import MainInfo from '../MainInfo/MainInfo'
import NearbyCampingLodging from '../../NearbyCampingLodging/NearbyCampingLodging'
import Trail from '../Trail/Trail'
import Weather from '../../Weather/Weather'
import PropTypes from 'prop-types'
import styles from './adventureInformation.module.css'

const AdventureInformation = ({ videoId, fluidImg }) => {
  const blogData = useContext(BlogContext).filter(
    post => post.videoId === videoId
  )[0]
  const {
    placeId,
    alt,
    displayName,
    parent_facility,
    prices,
    phone_number,
    address,
    website,
    google_map_link,
    hours,
    info_summary,
    park_map,
    camping,
    trail,
    trail_features,
    trail_type,
    type,
    slug,
    weather_station_coords,
    weather_station_name,
    hiking_map_coords,
  } = blogData

  const priceRange = computePriceRange(prices)
  const operatingHours = hours
    ? hours.map((day, index) => (
        <div className={styles.hours} key={index}>
          {day}
        </div>
      ))
    : null
  const pricing = prices.map((price, index) => (
    <div key={index} className={styles.prices}>
      <div className={styles.price}>{price.display}:</div>
      <div className={styles.price}>{price.value}</div>
    </div>
  ))
  return (
    <div className={styles.adventureInformation}>
      <ArticleTitle>{displayName}</ArticleTitle>
      <MainInfo
        fluidImg={fluidImg}
        alt={alt}
        rates={priceRange}
        phone_number={phone_number}
        address={address}
        website={website}
        google_map_link={google_map_link}
        name={displayName}
        parent_facility={parent_facility}
        park_map={park_map}
      />
      <div className={styles.infoSections}>
        {info_summary ? <div>{info_summary}</div> : null}
        <div className={styles.articleLinks}>
          <ArticleButton
            icon="read"
            url={`/${type}/${slug}`}
            color="#b63d2b"
            text="Read Review"
          />
          <ArticleButton
            icon="video"
            url="/videos"
            videoId={videoId}
            color="#9f211c"
            text="Watch Our Video"
          />
        </div>
        {operatingHours ? (
          <InfoSection icon="open" title="Hours of Operation">
            <div className={styles.hoursGrid}>{operatingHours}</div>
          </InfoSection>
        ) : null}
        {prices ? (
          <InfoSection icon="money" title="Prices">
            <div className={styles.pricing}>{pricing}</div>
          </InfoSection>
        ) : null}
        {trail ? (
          <InfoSection icon="hike" title={`${trail.name} Information`}>
            <Trail
              summary={trail.summary}
              difficulty={trail.difficulty}
              length={trail.length}
              ascent={trail.ascent}
              descent={trail.descent}
              high={trail.high}
              low={trail.low}
              videoId={videoId}
              hiking_map_coords={hiking_map_coords}
              trail_features={trail_features}
              trail_type={trail_type}
            />
          </InfoSection>
        ) : null}
        {weather_station_coords && weather_station_name ? (
          <InfoSection icon="weather" title="Weather">
            <Weather
              weatherStation={weather_station_name}
              latitude={weather_station_coords[0]}
              longitude={weather_station_coords[1]}
            />
          </InfoSection>
        ) : null}
      </div>
      {camping && camping[0] ? (
        <NearbyCampingLodging
          destination_id={placeId}
          campId={camping[0]}
          name={displayName}
        />
      ) : null}
    </div>
  )
}
AdventureInformation.propTypes = {
  videoId: PropTypes.string,
  fluidImg: PropTypes.object,
}
export default AdventureInformation
