import React from 'react'
import PropTypes from 'prop-types'
import { IconContext } from 'react-icons'

import {
  WiDaySunny,
  WiNightClear,
  WiRain,
  WiSnow,
  WiSleet,
  WiWindy,
  WiFog,
  WiCloudy,
  WiDayCloudy,
  WiNightAltCloudy,
} from 'react-icons/wi'

export const getWeatherIconComponent = icon => {
  let wi
  switch (icon) {
    case 'clear-day':
      wi = <WiDaySunny />
      break
    case 'clear-night':
      wi = <WiNightClear />
      break
    case 'rain':
      wi = <WiRain />
      break
    case 'snow':
      wi = <WiSnow />
      break
    case 'sleet':
      wi = <WiSleet />
      break
    case 'wind':
      wi = <WiWindy />
      break
    case 'fog':
      wi = <WiFog />
      break
    case 'cloudy':
      wi = <WiCloudy />
      break
    case 'partly-cloudy-day':
      wi = <WiDayCloudy />
      break
    case 'partly-cloudy-night':
      wi = <WiNightAltCloudy />
      break
    default:
      wi = null
  }
  return wi
}

const WeatherIcon = ({ icon, size }) => {
  const wiComponent = getWeatherIconComponent(icon)
  if (wiComponent)
    return (
      <IconContext.Provider value={{ size: size }}>
        {wiComponent}
      </IconContext.Provider>
    )
  return null
}

WeatherIcon.propTypes = {
  icon: PropTypes.string,
  size: PropTypes.string,
}

export default WeatherIcon
