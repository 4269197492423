import React, { useContext } from 'react'
import ArticleButton from '../../sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../../components/sharedComponents/ArticleTitle/ArticleTitle'
import BlogContext from '../../../contexts/blogData/blogData'
import BlogPost from '../../blogPost/BlogPost'
import CampingContext from '../../../contexts/campingData/campingData'
import { FaExclamationTriangle } from 'react-icons/fa'
import { getDestinationPlaceIDs } from '../../../helpers/helpers'
import { getFluidImage } from '../../../helpers/dynamicGatsbyImage'
import { IconContext } from 'react-icons'
import InfoSection from '../../sharedComponents/InfoSection/InfoSection'
import MainInfo from '../MainInfo/MainInfo'
import Weather from '../../Weather/Weather'
import PropTypes from 'prop-types'
import styles from './campingInformation.module.css'

const stripMarkupFromString = string => string.replace(/(<([^>]+)>)/gi, '')

const CampingInformation = ({ allImages, distances, videoId, fluidImg }) => {
  const campgroundData = useContext(CampingContext).filter(
    post => post.videoId === videoId
  )[0]

  const blogData = useContext(BlogContext)
  const {
    type,
    slug,
    placeId,
    rates,
    phone_number,
    address,
    reservation_page,
    parent_facility,
    park_map,
    displayName,
    alt,
    amenities,
    alerts,
    website,
    google_map_link,
    overview,
    directions,
    nearby,
    accessible_site_data,
    weather_station_coords,
    weather_station_name,
  } = campgroundData

  const accessibleSites = accessible_site_data
    ? accessible_site_data.accessible_sites.map((site, index) => (
        <div className={styles.accessibleSite} key={index}>
          <div className={styles.accessibleSiteInfo}>
            <div className={styles.accessibleSiteNumber}>
              Site Number:{' '}
              <span className={styles.siteNumber}>
                {site.accessible_site_name}
              </span>{' '}
            </div>
            <a
              className={styles.accessibleSiteLink}
              href={site.accessible_site_reservation_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here to Reserve This Site
            </a>
          </div>
        </div>
      ))
    : null

  const destinationPlaceIDs = getDestinationPlaceIDs(distances, placeId)

  const nearbyLocations = destinationPlaceIDs.length
    ? destinationPlaceIDs.map(id =>
        blogData.find(location => location.placeId === id)
      )
    : nearby.map(id => blogData.find(location => location.videoId === id))

  const nearbyPosts = nearbyLocations[0]
    ? nearbyLocations.map((post, index) => {
        const fluidImg = getFluidImage(post, allImages)
        return <BlogPost hero={fluidImg} key={index} post={post} />
      })
    : null

  const campgroundAmenities = amenities.map((amenity, index) => (
    <li className={styles.amenity} key={index}>
      {amenity}
    </li>
  ))

  const warnings = alerts
    ? alerts.map((alert, index) => (
        <div className={styles.alerts} key={index}>
          {stripMarkupFromString(alert.message)}
        </div>
      ))
    : null
  return (
    <div className={styles.campingInformation}>
      <ArticleTitle>{displayName}</ArticleTitle>
      <MainInfo
        fluidImg={fluidImg}
        alt={alt}
        rates={rates}
        phone_number={phone_number}
        address={address}
        website={website}
        google_map_link={google_map_link}
        reservation_page={reservation_page}
        parent_facility={parent_facility}
        park_map={park_map}
        type={type}
      />
      {warnings ? (
        <div className={styles.alertsSection}>
          <div>
            <IconContext.Provider value={{ color: '#e99a45', size: '40' }}>
              <FaExclamationTriangle />
            </IconContext.Provider>
          </div>
          <div>{warnings}</div>
        </div>
      ) : null}

      <div className={styles.description}>{overview}</div>
      <div className={styles.articleLinks}>
        <ArticleButton
          icon="read"
          url={`/${type}/${slug}`}
          color="#b63d2b"
          text="Read Review"
        />
        <ArticleButton
          icon="video"
          url="/videos"
          videoId={videoId}
          color="#9f211c"
          text="Watch Our Video"
        />
      </div>
      <InfoSection icon="bell" title="Amenities">
        <ul className={styles.amenities}>{campgroundAmenities}</ul>
      </InfoSection>
      {accessible_site_data && accessible_site_data.accessible_sites.length ? (
        <InfoSection icon="accessible" title="Accessible Sites">
          <div className={styles.numberAccessibleSites}>
            Number of Accessible Sites:{' '}
            {accessible_site_data.number_of_accessible_sites}
          </div>
          {accessibleSites}
        </InfoSection>
      ) : null}
      <InfoSection icon="weather" title="Weather">
        <Weather
          weatherStation={weather_station_name}
          latitude={weather_station_coords[0]}
          longitude={weather_station_coords[1]}
        />
      </InfoSection>
      {directions ? (
        <InfoSection icon="map" title="Directions">
          <div className={styles.description}>
            {stripMarkupFromString(directions)}
          </div>
        </InfoSection>
      ) : null}
      {nearbyPosts ? (
        <InfoSection icon="map" title="Nearby Things to Do">
          {nearbyPosts}
        </InfoSection>
      ) : null}
    </div>
  )
}
CampingInformation.propTypes = {
  distances: PropTypes.object,
  fluidImg: PropTypes.object,
  videoId: PropTypes.string,
  allImages: PropTypes.array,
}

export default CampingInformation
