import React, { useContext } from 'react'
import ArticleButton from '../../sharedComponents/articleButton/articleButton'
import ArticleTitle from '../../../components/sharedComponents/ArticleTitle/ArticleTitle'
import BlogContext from '../../../contexts/blogData/blogData'
import BlogPost from '../../blogPost/BlogPost'
import { getDestinationPlaceIDs } from '../../../helpers/helpers'
import { getFluidImage } from '../../../helpers/dynamicGatsbyImage'
import InfoSection from '../../sharedComponents/InfoSection/InfoSection'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import MainInfo from '../MainInfo/MainInfo'
import Weather from '../../Weather/Weather'
import PropTypes from 'prop-types'
import styles from './lodgingInformation.module.css'

const stripMarkupFromString = string => string.replace(/(<([^>]+)>)/gi, '')

const LodgingInformation = ({ allImages, distances, videoId, fluidImg }) => {
  const lodgingData = useContext(LodgingContext).filter(
    post => post.videoId === videoId
  )[0]

  const blogData = useContext(BlogContext)
  const {
    type,
    slug,
    placeId,
    rates,
    phone_number,
    address,
    reservation_page,
    parent_facility,
    park_map,
    displayName,
    alt,
    amenities,
    website,
    google_map_link,
    overview,
    directions,
    nearby,
    weather_station_coords,
    weather_station_name,
  } = lodgingData

  const destinationPlaceIDs = getDestinationPlaceIDs(distances, placeId)
  const nearbyLocations = destinationPlaceIDs.length
    ? destinationPlaceIDs.map(id =>
        blogData.find(location => location.placeId === id)
      )
    : nearby.map(id => blogData.find(location => location.videoId === id))

  const nearbyPosts = nearbyLocations[0]
    ? nearbyLocations.map((post, index) => {
        const fluidImg = getFluidImage(post, allImages)
        return <BlogPost hero={fluidImg} key={index} post={post} />
      })
    : null

  const lodgingAmenities = amenities.map((amenity, index) => (
    <li className={styles.amenity} key={index}>
      {amenity}
    </li>
  ))

  return (
    <div className={styles.campingInformation}>
      <ArticleTitle>{displayName}</ArticleTitle>
      <MainInfo
        fluidImg={fluidImg}
        alt={alt}
        rates={rates}
        phone_number={phone_number}
        address={address}
        website={website}
        google_map_link={google_map_link}
        reservation_page={reservation_page}
        parent_facility={parent_facility}
        name={displayName}
        park_map={park_map}
        type={type}
      />

      <div className={styles.description}>{overview}</div>
      <div className={styles.articleLinks}>
        <ArticleButton
          icon="read"
          url={`/${type}/${slug}`}
          color="#b63d2b"
          text="Read Review"
        />
        <ArticleButton
          icon="video"
          url="/videos"
          videoId={videoId}
          color="#9f211c"
          text="Watch Our Video"
        />
      </div>
      <InfoSection icon="bell" title="Amenities">
        <ul className={styles.amenities}>{lodgingAmenities}</ul>
      </InfoSection>
      <InfoSection icon="weather" title="Weather">
        <Weather
          weatherStation={weather_station_name}
          latitude={weather_station_coords[0]}
          longitude={weather_station_coords[1]}
        />
      </InfoSection>
      {directions ? (
        <InfoSection icon="map" title="Directions">
          <div className={styles.description}>
            {stripMarkupFromString(directions)}
          </div>
        </InfoSection>
      ) : null}
      {nearbyPosts ? (
        <InfoSection icon="map" title="Nearby Things to Do">
          {nearbyPosts}
        </InfoSection>
      ) : null}
    </div>
  )
}
LodgingInformation.propTypes = {
  distances: PropTypes.object,
  fluidImg: PropTypes.object,
  videoId: PropTypes.string,
  allImages: PropTypes.array,
}

export default LodgingInformation
